import { GA } from './google_analytics.js';
import SweetScroll from "sweet-scroll";
import Modern from "./modernizr-custom";
import $ from "jquery";
import Parsley from "parsleyjs";

export function onready() {
    var scrollPosition = parseInt(window.pageYOffset, 10),
        animating = false;

    function injectVendorFiles() {
        GA.init();
    }

    function isMobile() {
        let userAgent = navigator.userAgent.toLowerCase();
        return /mobi|android|touch|mini/i.test(userAgent);
    }

    let nav = document.querySelector("nav")

    function showNavBar(scrollPosition) {
        if (scrollPosition > 25 && !$(nav).hasClass('fixed')) {
            $(nav).addClass('fixed');
            $("body, section#welcome").addClass('scrolled');
        }

        if (scrollPosition < 25 && $(nav).hasClass('fixed')) {
            $(nav).removeClass('fixed');
            $("body, section#welcome").removeClass('scrolled');
        }
    }

    showNavBar(scrollPosition);

    window.addEventListener('scroll', function(e) {
        scrollPosition = parseInt(window.pageYOffset, 10);
        if (!animating) {
            window.requestAnimationFrame(function() {
                showNavBar(scrollPosition);
                animating = false;
            });
        }
        animating = true;
    });

    var $window = $(window);

    function check_if_in_view() {
        var window_height = $window.height();
        var window_top_position = $window.scrollTop();
        var window_bottom_position = (window_top_position + window_height);

        $.each($animation_elements, function() {
            var $element = $(this);
            var element_height = $element.outerHeight();
            var element_top_position = $element.offset().top;
            var element_bottom_position = (element_top_position + element_height);

            //check to see if this current container is within viewport
            if ((element_bottom_position >= window_top_position) &&
                (element_top_position <= window_bottom_position)) {
                $element.addClass('in-view');
                updateNavBar($element.last().parents("section").attr('id'));
            } else {
                $element.removeClass('in-view');
            }
        });
    }

    function updateNavBar(activeView) {
        let currentActive = $("nav a.active");

        if ($("#company").offset().top - $window.scrollTop() > 300) {
            activeView = "welcome";
        }

        if ($(currentActive).attr('href') == activeView) {
            return true;
        }

        $(currentActive).removeClass('active');
        $(`nav a[href='#${activeView}']`).addClass('active');
    }

    $window.on('load', injectVendorFiles);

    let subscriptionForm = document.querySelector("form#subscribe");

    let formState = $(subscriptionForm).parsley();

    $(subscriptionForm).on('submit', e => {
        e.preventDefault();
        if (formState.isValid()) {
            let name = $("form#subscribe input[name='name']").val();
            let email = $("form#subscribe input[name='email']").val();

            $.post('/submit', { name, email }).then(data => {
                console.debug(data);
                $(subscriptionForm).html(data[0]);
            }).fail(err => {
                console.error(err);
            })
        }
    })

    if (!isMobile() && !Modernizr.mq('(max-width:640px)')) {
        new SweetScroll({ offset: -126 });

        //Cache reference to window and animation items
        var $animation_elements = $('.animation-element');
        $window.on('scroll resize', check_if_in_view);
        $window.trigger('scroll');
    }

}